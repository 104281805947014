export default {
    parsedPaymentData: {},
    selectedCardIndex: null,
    selectedCard: null,
    INVOICE_ID: "",
    TERMINAL: "",
    AMOUNT: 0,
    ACCESS_TOKEN:"",
    paymentData: {},
    isLoading: true,
    errors: {},
    successMessage: "",
    failureMessage: "",
    sendCallbackEveryFailedAttempt: 3,
    attemptCount: 0,
    hf: null,
    isHostedFieldsVisible: false,
    accessTokenValue: '',
    formattedPaymentData: '',
    storedCheckoutCart: {},
    iframeSrcGoggle: "",
    iframeSrcApple: "",
    isMacOSOrIOS: false,
    interval: null,
    paymentStatus: "",
    failureInterval: null,
    pendingInterval: null,
    snackbarError: false,
    color: "red",
    errorMessage: "",
    timeout: 3000,
    selectedMethod: 'payWithCard',
    isActive: false,
    cardDetailsCard: [],
    userSession: "",
    cardDetailsCheck: 0,
    newCardDetailsCheck: 0,
    isTokenizedCard: false,
    cardholderName: '',
    isTyping: false
}