<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { bucketUrlForImages } from "@/api/api.js";
import { mapGetters } from "vuex";

export default {
  name: "app",

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["getfevIcon", "getContentID", "getTitle", "getAllData"]),
  },
  created() {
    this.$store.dispatch("fetch_custom_raffle").then(() => {
      this.contentID = this.getContentID;
      const siteFavicon = document.getElementById("favicon");
      const favicon_apple = document.getElementById("favicon_apple");
      const favicon_icon = document.getElementById("favicon_icon");
      const mask_icon = document.getElementById("mask_icon");
      const shortcut_icon = document.getElementById("shortcut_icon");
      const title = document.getElementById("title");
      title.innerHTML = this.getAllData.Items?.content?.name;

      if (this.getfevIcon && this.getAllData?.Items?.template?.favicon?.is) {
        siteFavicon.href = `${bucketUrlForImages}/${this.contentID}/${this.getfevIcon}`;
        favicon_apple.href = `${bucketUrlForImages}/${this.contentID}/${this.getfevIcon}`;
        favicon_icon.href = `${bucketUrlForImages}/${this.contentID}/${this.getfevIcon}`;
        mask_icon.href = `${bucketUrlForImages}/${this.contentID}/${this.getfevIcon}`;
        shortcut_icon.href = `${bucketUrlForImages}/${this.contentID}/${this.getfevIcon}`;
      } else {
        console.log("No favicon found in localStorage.");
      }

      if (this.getTitle) {
        console.log();

        console.log(this.getTitle);

        document.title = this.getTitle;
      } else {
        console.log("No page title found in store.");
      }
    });
  },
};
</script>
