<!-- SuccessSnackbar.vue -->

<template>
    <v-snackbar :model-value="showSnackbar" color="error" class="message" :style="{ textAlign: 'center' }">
      {{ message }}
    </v-snackbar>
    </template>
    
    <script>
    export default {
      props: {
        message: {
          type: String,
          default: "Successful",
        },
        showSnackbar: {
          type: Boolean,
          default: false
        }
      },
    };
    </script>
    
    <style scoped>
    .message {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    </style>
    