import { apiURL } from "@/api/api";

export const fetchTemplate = async () => {
    try {
        const response = await fetch(apiURL.fetchUserTemplate, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        });

        if (!response.ok) {
            // const data= await response.json();
            // throw new Error('Failed to fetch template');
            // document.getElementById("app").innerHTML=data.message
            const data = await response.json();
            const errorMessage = data.message;

            // Create a container div with inline styling for centering
            const container = document.createElement('div');
            container.style.display = 'flex';
            container.style.justifyContent = 'center';  // Center horizontally
            container.style.alignItems = 'center';      // Center vertically
            container.style.height = '100vh';           // Full viewport height
            container.style.width = '100vw';            // Full viewport width
            container.style.backgroundColor = '#f5f5f5'; // Optional: Background color

            // Create an h4 element with inline styling for red text
            const errorElement = document.createElement('h4');
            errorElement.style.color = 'red';
            errorElement.style.textAlign = 'center'; // Center text inside the h4
            errorElement.textContent = errorMessage;

            // Append the error element to the container
            container.appendChild(errorElement);

            // Insert the container into the DOM
            const appElement = document.getElementById('app');
            appElement.innerHTML = ''; // Clear any existing content
            appElement.appendChild(container);
        }

        const data = await response.json();
        console.log({ Theme123: data.Items.template.theme.light_css });
        let enterNowButtonColor = data?.Items?.template?.theme?.light_css?.enterNow?.fontColor ? data.Items.template.theme.light_css.enterNow.fontColor : data.Items.template.theme.light_css.secondaryColor;

        document.documentElement.style.setProperty('--primary-color', data.Items.template.theme.light_css.primaryColor);
        document.documentElement.style.setProperty('--secondary-color', data.Items.template.theme.light_css.secondaryColor);

        //Hero Title CSS
        document.documentElement.style.setProperty('--heroTitle-font', data.Items.template.theme.light_css.heroTitle.font);
        document.documentElement.style.setProperty('--heroTitle-fontColor', data.Items.template.theme.light_css.heroTitle.fontColor);
        document.documentElement.style.setProperty('--heroTitle-fontWeight', data.Items.template.theme.light_css.heroTitle.fontWeight);
        document.documentElement.style.setProperty('--heroTitle-fontSize', data.Items.template.theme.light_css.heroTitle.fontSize);

        //Raffle Title CSS
        document.documentElement.style.setProperty('--raffleTitle-fontColor', data.Items.template.theme.light_css.raffleTitle?.fontColor);

        //Hero Description CSS
        document.documentElement.style.setProperty('--heroDescription-font', data.Items.template.theme.light_css.heroDescription.font);
        document.documentElement.style.setProperty('--heroDescription-fontColor', data.Items.template.theme.light_css.heroDescription.fontColor);
        document.documentElement.style.setProperty('--heroDescription-fontWeight', data.Items.template.theme.light_css.heroDescription.fontWeight);
        document.documentElement.style.setProperty('--heroDescription-fontSize', data.Items.template.theme.light_css.heroDescription.fontSize);
        //Hero Button CSS
        document.documentElement.style.setProperty('--heroButton-font', data.Items.template.theme.light_css.heroButton.font);
        document.documentElement.style.setProperty('--heroButton-fontColor', data.Items.template.theme.light_css.heroButton.fontColor);
        document.documentElement.style.setProperty('--heroButton-fontWeight', data.Items.template.theme.light_css.heroButton.fontWeight);
        document.documentElement.style.setProperty('--heroButton-fontSize', data.Items.template.theme.light_css.heroButton.fontSize);
        document.documentElement.style.setProperty('--enterNow-fontColor', enterNowButtonColor);

        //Hero PillButton CSS
        document.documentElement.style.setProperty('--heroPillButton-font', data.Items.template.theme.light_css.heroPillButton.font);
        document.documentElement.style.setProperty('--heroPillButton-fontColor', data.Items.template.theme.light_css.heroPillButton.fontColor);
        document.documentElement.style.setProperty('--heroPillButton-fontWeight', data.Items.template.theme.light_css.heroPillButton.fontWeight);
        document.documentElement.style.setProperty('--heroPillButton-fontSize', data.Items.template.theme.light_css.heroPillButton.fontSize);

        //Hero InSupport CSS
        document.documentElement.style.setProperty('--heroInSupportOf-font', data.Items.template.theme.light_css?.heroInSupportOf ? data.Items.template.theme.light_css.heroInSupportOf.font : 'Gabriato');
        document.documentElement.style.setProperty('--heroInSupportOf-fontColor', data.Items.template.theme.light_css?.heroInSupportOf ? data.Items.template.theme.light_css.heroInSupportOf.fontColor : '#000000');
        document.documentElement.style.setProperty('--heroInSupportOf-fontWeight', data.Items.template.theme.light_css?.heroInSupportOf ? data.Items.template.theme.light_css.heroInSupportOf.fontWeight : data.Items.template.theme.light_css.heroDescription.fontWeight);
        document.documentElement.style.setProperty('--heroInSupportOf-fontSize', data.Items.template.theme.light_css?.heroInSupportOf ? data.Items.template.theme.light_css.heroInSupportOf.fontSize : data.Items.template.theme.light_css.heroDescription.fontSize);


        //Content Title CSS
        document.documentElement.style.setProperty('--contentTitle-font', data.Items.template.theme.light_css.contentTitle.font);
        document.documentElement.style.setProperty('--contentTitle-fontColor', data.Items.template.theme.light_css.contentTitle.fontColor);
        document.documentElement.style.setProperty('--contentTitle-fontWeight', data.Items.template.theme.light_css.contentTitle.fontWeight);
        document.documentElement.style.setProperty('--contentTitle-fontSize', data.Items.template.theme.light_css.contentTitle.fontSize);
        //Content Description CSS
        document.documentElement.style.setProperty('--contentDescription-font', data.Items.template.theme.light_css.contentDescription.font);
        document.documentElement.style.setProperty('--contentDescription-fontColor', data.Items.template.theme.light_css.contentDescription.fontColor);
        document.documentElement.style.setProperty('--contentDescription-fontWeight', data.Items.template.theme.light_css.contentDescription.fontWeight);
        document.documentElement.style.setProperty('--contentDescription-fontSize', data.Items.template.theme.light_css.contentDescription.fontSize);
        //Content Button CSS
        document.documentElement.style.setProperty('--contentButton-font', data.Items.template.theme.light_css.contentButton.font);
        document.documentElement.style.setProperty('--contentButton-fontColor', data.Items.template.theme.light_css.contentButton.fontColor);
        document.documentElement.style.setProperty('--contentButton-fontWeight', data.Items.template.theme.light_css.contentButton.fontWeight);
        document.documentElement.style.setProperty('--contentButton-fontSize', data.Items.template.theme.light_css.contentButton.fontSize);

        //Content PillButton CSS
        document.documentElement.style.setProperty('--contentPillButton-font', data.Items.template.theme.light_css.contentPillButton.font);
        document.documentElement.style.setProperty('--contentPillButton-fontColor', data.Items.template.theme.light_css.contentPillButton.fontColor);
        document.documentElement.style.setProperty('--contentPillButton-fontWeight', data.Items.template.theme.light_css.contentPillButton.fontWeight);
        document.documentElement.style.setProperty('--contentPillButton-fontSize', data.Items.template.theme.light_css.contentPillButton.fontSize);

        document.documentElement.style.setProperty('--check-background', data.Items.template.theme.light_css.secondaryColor);

        return data; // Return the parsed JSON data
    } catch (error) {
        console.log("Error fetching template:", error);
        throw error; // Propagate the error
    }
};