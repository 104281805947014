import { useToast } from 'vue-toastification';

const toast = useToast();

const success = (msg) => {
  toast.success(msg, {
    timeout: 4000,
    position: 'top-center',
    theme: 'colored',
    style: {
      background: '#1877f2',
      color: 'white',
    },
  });
};

const errorMsg = (msg) => {
  console.log({Naanu:msg});
  
  toast.error(msg, {
    timeout: 3000,
    position: 'top-center',
    theme: 'colored',
    style: {
      background: '#f44336',
      color: 'white',
    },
  });
};

const loading = (msg) => {
  toast.loading(msg, {
    timeout: 0,
    position: 'top-center',
    theme: 'colored',
    style: {
      background: '#1877f2',
      color: 'white',
    },
  });
};

const custom = (msg, options = {}) => {
  toast(msg, options);
};

const dismiss = (toastId) => {
  toast.dismiss(toastId);
};

const remove = (toastId) => {
  toast.remove(toastId);
};

export { success, errorMsg, loading, custom, dismiss, remove };
