<template>
  <div>
    <!-- web carousel sec -->
    <div class="autoplay">
      <img
        v-for="charityimage in charityList"
        :src="charityimage.CharityImage"
        alt=""
        class="customCarouselImg"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "@/assets/css/customRaffleLandingPage.css";
import "@/assets/css/global.css";
import "@/assets/css/toggle.css";
import "slick-carousel/slick/slick.min.js";
import "slick-carousel/slick/slick.css";
import "@/assets/slick/slick.css";
import "@/assets/slick/slick-theme.css";
import moment from "moment";
import charityList from "@/mixins/charity.json";
import { apiURL } from "@/api/api";

export default {
  data: () => ({
    countdownTimers: [
      {
        days: "00",
        hours: "00",
        minutes: "00",
        secs: "00",
      },
    ],
    customraffleData: [],
    customraffleData1: [],
    raffleCode: "",
    charityRef: "",
    charityList,
    selectedIndex: 0,
    thumbnails: [
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp",
        alt: "Thumbnail 1",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb3.webp",
        alt: "Thumbnail 2",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb9.webp",
        alt: "Thumbnail 3",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb4.webp",
        alt: "Thumbnail 4",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb5.webp",
        alt: "Thumbnail 5",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb6.webp",
        alt: "Thumbnail 6",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb7.webp",
        alt: "Thumbnail 7",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb8.webp",
        alt: "Thumbnail 8",
      },
    ],
  }),
  mounted() {
    this.$nextTick(() => {
      this.slick();
    });
  },
  created() {    
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    this.charityRef = !urlParams.get("charity_ref")
      ? 0
      : urlParams.get("charity_ref");    
  },
  computed: {
    selectedImage() {
      return this.thumbnails[this.selectedIndex].src;
    },
  },
  methods: {
    slick() {
      $(".autoplay").slick({
        slidesToShow: 18,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 0,
            },
          },
        ],
      });
    },
    selectImage(index) {
      this.selectedIndex = index;
    },
    prevImage() {
      this.selectedIndex =
        (this.selectedIndex - 1 + this.thumbnails.length) %
        this.thumbnails.length;
    },
    nextImage() {
      this.selectedIndex = (this.selectedIndex + 1) % this.thumbnails.length;
    },
    startCountdown() {
      setInterval(() => {
        const inputDateStr = "30th November 2023 22:00";
        const parsedDate = new Date(
          inputDateStr.replace(/(\d+)(st|nd|rd|th)/, "$1")
        );
        const formattedDate = parsedDate.toISOString();
        const now = moment();
        const endOfYear = moment(formattedDate);
        const duration = moment.duration(endOfYear.diff(now));
        const totalDays = duration.asDays();
        const days = String(parseInt(totalDays)).padStart(2, "0");
        const hours = String(duration.hours()).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        const seconds = String(duration.seconds()).padStart(2, "0");
        this.countdownTimers = [
          {
            days: days,
            hours: hours,
            minutes: minutes,
            secs: seconds,
          },
        ];
      }, 1000);
    },
    async fetch_custom_raffle() {
      try {
        await fetch(
          `${apiURL.fetchCustomRaffle}/?raffleCode=${this.raffleCode}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            this.customraffleDetails = data;
            // console.log("this.customraffleDetails", this.customraffleDetails)
            this.customraffleData =
              this.customraffleDetails.data.customRaffle[0];
            this.customraffleData1 = this.customraffleDetails.data.raffle[0];
            this.startCountdown();
          });
      } catch (error) {
        console.log("error:", error);
      }
    },

    formatDate(dateString) {
      return moment(dateString).format("Do MMMM YYYY [at] HH:mm");
    },
    go_to_rafflepage() {
      const queryParams = {
        charityRef: this.charityRef,
        raffleCode: this.raffleCode,
      };
      this.$router
        .push({
          name: "RafflePage",
          params: queryParams,
        })
        .catch((err) => {
          console.error(err);
        });
      // this.$router.push(`/customRafflePage/${this.charityRef}/${this.raffleCode}`)
    },
  },
};
</script>