<!-- Loader.vue -->
<template>
    <div class="loader">
        <div class="loader-inner"></div>
    </div>
</template>

<style scoped>
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    /* semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* ensure it's on top */
}

.loader-inner {
    border: 6px solid #f3f3f3;
    /* Light grey */
    border-top: 6px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>