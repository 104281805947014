import { createRouter, createWebHistory } from "vue-router";
import rafflePage from '@/views/Templates/gcr/rafflePage.vue'
import paymentPage from '@/components/common/gcr/raffle/dnaPaymentModal.vue'

const routes = [
  {
    path: "/",
    name: "landingPage",
    component: () => import("@/views/main.vue"),
  },
  {
    path: "/raffle",
    name: "rafflePage",
    component: rafflePage,
  },
  {
    path: "/payments",
    name: "paymentsPage",
    component: paymentPage,
  },
  {
    path: "/paymentFailure",
    name: "PaymentFailure",
    component: function () {
      return import("../views/paymentFailureNew.vue");
    },
  },
  {
    path: "/paymentSuccess",
    name: "PaymentSuccess",
    component: function () {
      return import("../views/paymentSuccessNew.vue");
    },
  },
  {
    path: "/stripeCheckout",
    name: "StripeCheckout",
    component: function () {
      return import("../views/stripePayment/stripeCheckout.vue");
    },
  },
  // {
  //   path: "/",
  //   name: "landingPage",
  //   component: () => import("@/components/customRaffles/rafflelandingPage.vue"),
  // },
  // {
  //   path: "/customRafflePage/:charityRef/:raffleCode",
  //   name: "rafflePage",
  //   component: () => import("@/components/customRaffles/customRafflePage.vue"),
  // },
  // {
  //   path: "/payment/success",
  //   name: "successPage",
  //   component: () =>
  //     import("@/components/customRaffles/rafflesConfirmationPage.vue"),
  // },
  // {
  //   path: "/payment/failed",
  //   name: "customRaffleFailurePage",
  //   component: () =>
  //     import("@/components/customRaffles/customRaffleFailurePage.vue"),
  // },
  {
    path: "/pageNotFound",
    name: "pageNotFound",
    component: () => import("@/views/Templates/reference/pageNotFoundPage.vue"),
  },
  // {
  //   path: "/:catchAll(.*)*",
  //   name: "notFound",
  //   component: () => import("@/components/customRaffles/pageNotFoundPage.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
