import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { fetchTemplate } from './plugins/theme'
import { loadFonts } from './plugins/webfontloader'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

loadFonts()

const app = createApp(App)

fetchTemplate()
  .then(data => {
    app.provide('templateTheme', data.Items.template.theme);
    app.use(router);
    app.use(Toast);
    app.use(store);
    app.use(vuetify);
    app.mount('#app');
  })
  .catch(error => {
    console.error('Failed to fetch template:', error);
    // Handle error as needed
  });

