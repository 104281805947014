import { createStore } from 'vuex';
import { apiURL } from "@/api/api";


export default createStore({
  state: {
    title:"",
    poweredBy: "",
    headerData: null,
    headerLogoSrc: "",
    themeData: {},
    contentID: "",
    footerTitle: "",
    footerText: "",
    footerData: {},
    footerContent: {},
    raffleData: {},
    prize_data: {},
    successData: {},
    templateTheme: {},
    fevIcon: "",
    all_data:{},
  },
  getters: {
    getPrizeData: (state) => state.prize_data,

    getHeaderData: (state) => state.headerData,

    getPoweredBy: (state) => state.poweredBy,

    getHeaderLogoSrc: (state) => state.headerLogoSrc,

    getThemeData: (state) => state.themeData,

    getTemplate: (state) => state.template,

    getContentID: (state) => state.contentID,

    getFooterTitle: (state) => state.footerTitle,

    getFooterText: (state) => state.footerText,

    getFooterData: (state) => state.footerData,

    getRaffleData: (state) => state.raffleData,

    getFooterContent: (state) => state.footerContent,

    getSuccessData: (state) => state.successData,

    getTemplateTheme: (state) => state.templateTheme,

    getfevIcon: (state) => state.fevIcon,

    getTitle:(state)=>state.title,

    getAllData: (state) => state.all_data,
  },
  mutations: {
    prize_data(state, payload) {
      state.prize_data = payload;
    },
    all_data(state, payload) {
      console.log({ payload });
      state.all_data = payload;
    },
    powered_by(state, payload) {
      state.poweredBy = payload;
    },
    header_data(state, payload) {
      state.headerData = payload;
    },
    footer_data(state, payload) {
      state.footerData = payload;
    },
    updateHeaderLogoSrc(state, payload) {
      state.headerLogoSrc = payload;
    },
    theme_data(state, payload) {
      state.themeData = payload;
    },
    template_data(state, payload) {
      state.template = payload;
    },
    fev_Icon(state, payload) {
      state.fevIcon = payload;
    },
    Content_ID(state, payload) {
      state.contentID = payload;
    },
    footer_title(state, payload) {
      state.footerTitle = payload;
    },
    footer_text(state, payload) {
      state.footerText = payload;
    },
    raffle_data(state, payload) {
      state.raffleData = payload;
    },
    updateFooterData(state, payload) {
      state.footerData = payload;
    },
    updateFooterContent(state, payload) {
      state.footerContent = payload;
    },
    set_fetch_success_data(state, payload) {
      state.successData = payload;
    },
    template_theme(state, payload) {
      state.templateTheme = payload;
    },
    title(state, payload) {
      state.title = payload;
    },
  },
  actions: {
    async fetch_custom_raffle({ commit }) {
      try {
        await fetch(apiURL.fetchUserTemplate, {
          method: "POST",
          // body: JSON.stringify({
          //   domain: "http://localhost:8080/"
          // }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log({ data });
            commit("all_data", data);
            const template = data.Items.template;
            const content = data.Items.content;
            const raffle = data.Items.raffle;

            this.contentID = content.sort_id?.split("#")[1];
            const themeData = template.theme.light_css;
            const headerData = {
              is: template?.header?.is,
              name: template?.header?.name?.is ? content.header.name.value : "",
              nameImgSrc: template?.header?.name?.is_image
                ? content.header.name.value
                : "",
              nameLink: template?.header?.name?.is_link
                ? content.header.name.link.value
                : "",

              logo: template?.header?.logo?.is ? content.header.logo.is : "",
              logoImgSrc: template?.header?.logo?.is_image
                ? content.header.logo.value
                : "",
              logoLink: template?.header?.logo?.is_link
                ? content.header.logo.link.value
                : "",

              poweredByLogo: template?.header?.powered_by_logo?.is
                ? content.header.powered_by_logo.is
                : "",
              poweredByLogoImgSrc: template?.header?.powered_by_logo?.is_image
                ? content.header.powered_by_logo.value
                : "",
              poweredByLogoLink: template?.header?.powered_by_logo?.is_link
                ? content.header.powered_by_logo.link.value
                : "",
            };
            this.headerData = headerData;
            this.landingContentTemplateDetails =
              template?.main?.page_contents?.pages[0].contents;
            this.heroLandingData = {
              is: template?.main?.page_contents?.pages[0]?.hero?.is,
              button: template?.main?.page_contents?.pages[0]?.hero?.button?.is
                ? content.main.page_contents.pages[0].hero.button.value
                : "",
              pillButton:
                template?.main?.page_contents?.pages[0]?.hero?.pillButton?.is,
              backgroundImage: template?.main?.page_contents?.pages[0]?.hero
                ?.img?.is
                ? content.main.page_contents.pages[0].hero.img.value
                : "",
              description: template?.main?.page_contents?.pages[0]?.hero
                ?.description?.is
                ? content.main.page_contents.pages[0].hero.description.value
                : "",
              title: template?.main?.page_contents?.pages[0]?.hero?.title?.is
                ? content.main.page_contents.pages[0].hero.title.value
                : "",

              inSupportOf:
                template?.main?.page_contents?.pages[0]?.hero?.inSupportOf?.is,
              inSupportOfImgSrc: template?.main?.page_contents?.pages[0]?.hero
                ?.inSupportOf?.is
                ? content.main.page_contents.pages[0].hero.inSupportOf.value
                : "",
              inSupportOfLink: template?.main?.page_contents?.pages[0]?.hero
                ?.inSupportOf?.is_link
                ? content.main.page_contents.pages[0].hero.inSupportOf.link
                    .value
                : "",
            };

            this.footerContent = content.footer;
            this.title=content.name;
            this.contentData = content.main.page_contents.pages[0].contents;
            this.landingHeroContent = content.main.page_contents.pages[0].hero;

            this.countDownTime = raffle.drawing_in;
            commit("Content_ID", this.contentID);
            commit("theme_data", themeData);
            commit("header_data", headerData);
            commit("footer_data", template.footer);
            commit("updateFooterContent", this.footerContent);
            commit("raffle_data", raffle);
            commit("template_data", template);
            commit("fev_Icon", content.favicon.value);
            commit("title", content.name);

            commit("powered_by", content.header.powered_by_logo?.value || "");
            commit("footer_text", content.footer.text || "");
            commit("footer_title", content.footer.title || "");
            commit("prize_data", data.Items.prize || "");
          });
      } catch (error) {
        console.log("error:", error);
      }
    },

    async fetchSuccessData({ commit }, cart_id) {
      try {
        const requestBody = { cart_id };
        const response = await fetch(apiURL.fetchSuccessPageData, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            // Authorization: JSON.parse(userSession),
          },
          body: JSON.stringify(requestBody),
        });
        const data = await response.json();
        commit("set_fetch_success_data", data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    },
  },
  modules: {},
});
