<template>
  <v-row class="container">
    <v-col cols="12" sm="12" md="8" class="left-column">
      <v-row>
        <v-col sm="12" md="12">
          <div class="align-center">
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp"
              alt=""
              class="mainImageForRaffle imageFitStyle"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <div class="justify-space threeFourthWidth">
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp"
              alt=""
              class="smallImageForRaffle imageFitStyle"
            />
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp"
              alt=""
              class="smallImageForRaffle imageFitStyle"
            />
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp"
              alt=""
              class="smallImageForRaffle imageFitStyle"
            />
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp"
              alt=""
              class="smallImageForRaffle imageFitStyle"
            />
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp"
              alt=""
              class="smallImageForRaffle imageFitStyle"
            />
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp"
              alt=""
              class="smallImageForRaffle imageFitStyle"
            />
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp"
              alt=""
              class="smallImageForRaffle imageFitStyle"
            />
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp"
              alt=""
              class="smallImageForRaffle imageFitStyle"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <div class="textBox">
            <v-row>
              <v-col cols="12" sm="12">
                <h5>The Prize</h5>
              </v-col>
              <v-col cols="12" sm="12">
                <p>
                  Body copy section: Get ready to win big with our Grand Raffle!
                  Here’s your chance to take home incredible prizes, while
                  supporting a great cause. Don’t miss out on this fantastic
                  opportunity to win amazing prizes while making a positive
                  impact. Get your tickets now and join the excitement! And the
                  best part?
                </p>
                <p>
                  You’ll have your choice of over 80 charities to support - or
                  choose to split your donation between all!
                </p>
                <p>
                  A winner will be drawn on the 25th November 2023 at 20:00.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <h5>Prize details</h5>
              </v-col>
              <v-col cols="12" sm="12">
                <p>
                  Any details about prize specifics and how the prize will be
                  received etc
                </p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12" md="4" class="right-column">
      <v-card class="raffleTicketsCard" variant="tonal">
        <v-card-title></v-card-title>
        <v-card-subtitle>
          <h5>This is an example raffle title</h5>
        </v-card-subtitle>
        <v-card-item>
          <span class="price-pill">£5.00 per ticket</span>
          <h5>How many tickets would you like?</h5>
          <v-row>
            <v-col cols="12" sm="3">
              <span class="additionPillButton">-</span>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="noOfTicketsBox"> 5 </span>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="additionPillButton">+</span>
            </v-col>
          </v-row>
        </v-card-item>
        <v-card-actions>
          <v-btn>Click me</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.align-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.justify-space {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.threeFourthWidth {
  margin-left: 6vw;
  width: 48vw;
  gap: 2vw;
}
.textBox {
  padding: 2vw 3vw;
}
.container {
  display: flex;
}
.left-column {
  flex: 2;
}
.right-column {
  flex: 1;
}
.price-pill {
  width: 180px;
  height: 30px;
  top: 381px;
  left: 1084px;
  gap: 0px;
  border-radius: 27.5px 0px 0px 0px;
  opacity: 0px;
  background-color: #46b6a1;
  color: #ffffff;
}
.raffleTicketsCard {
  width: 414px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.additionPillButton {
  width: 40px;
  height: 40px;
  top: 565px;
  left: 1088px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  opacity: 0px;
  background: #46b6a1;
  color: #ffffff;
}
.noOfTicketsBox {
  width: 72px;
  height: 48px;
  top: 561px;
  left: 1138px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  color: auto;
}
</style>
