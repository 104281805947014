<template>
  <div>
    <div
      class="row customRaffleNavBg m-0 position-relative"
      :style="{ '--font-color': themeData.primaryColor }"
    >
      <div class="col-4 pe-0 d-flex align-items-center h-100">
        <a
          v-if="
            this.templateSample?.header?.powered_by_logo?.is &&
            this.isPowerLogoRequired
          "
          :href="homePageUrl"
          class="d-block"
        >
          <img
            :src="fetchImage(headerData.poweredByLogoImgSrc)"
            class="customNavRaffoluxImg imageFitStyle support-logo-class"
            alt=""
          />
        </a>
      </div>

      <div class="col-4">
        <div class="d-flex justify-content-center">
          <img
            v-if="this.templateSample?.header?.logo?.is"
            :src="fetchImage(headerData.logoImgSrc)"
            class="greatChristmasRaffleImg imageFitStyle"
            alt=""
            :style="{
              maxWidth: '200px',
              height: 'auto',
              borderRadius: '8px',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bucketUrlForImages } from "@/api/api.js";
import "@/assets/css/customRaffleLandingPage.css";
import "@/assets/css/global.css";
import "@/assets/css/toggle.css";

export default {
  data() {
    return {
      homePageUrl: "/",
    };
  },
  mounted() {},
  created() {},
  components: {},
  props: {
    contentID: {
      type: String,
      required: true,
    },
    poweredBy: {
      type: String,
      required: true,
    },
    headerLogoSrc: {
      type: String,
      required: true,
    },
    headerData: {
      type: Object,
      required: true,
    },
    themeData: {
      type: Object,
      required: true,
    },
    templateSample: {
      type: Object,
      required: true,
    },
    isPowerLogoRequired: {
      type: Boolean,
      required: true,
    },
  },
  computed: {},
  methods: {
    fetchImage(filenames) {
      return `${bucketUrlForImages}/${this.contentID}/${filenames}`;
    },
  },
};
</script>

<style scoped>
.customRaffleNavBg {
  background-color: var(--font-color) !important;
  z-index: 1;
}

.support-logo-class {
  width: 67px;
  height: 32px;
}

.imageFitStyle{
  object-fit: contain; 
}

@media only screen and (max-width: 767px) {
  .support-logo-class {
    width: 65.185px;
    height: 32px;
  }
}
</style>
