<template>
  <div
    class="row customRaffleNavBg m-0 position-relative"
    v-if="pageTemplate?.header?.is"
  >
    <div class="col-4 pe-0">
      <!-- src="https://static.raffolux.com/static/website/redev_images/poweredByRaffoluxImg.svg" -->
      <img
        :src="fetchImage(this.pageHeaderContent?.logo?.value)"
        class="customNavRaffoluxImg"
        alt=""
        @click="go_to_homepage"
        v-if="this.pageTemplate?.header?.logo?.is"
      />
    </div>

    <div class="col-4">
      <div class="d-flex justify-content-center">
        <img
          src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/GCR-logo.png"
          class="greatChristmasRaffleImg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { markRaw } from "vue";
import {bucketUrlForImages} from '@/api/api.js';


export default markRaw({
  data() {
    headerLogo: "https://static.raffolux.com/static/website/redev_images/poweredByRaffoluxImg.svg";
  },
  props: {
    contentID: {
      type: String,
      required: false,
    },
    pageTemplate: {
      type: Object,
      required: true,
    },
    pageHeaderContent: {
      type: Object,
      required: true,
    },
  },
  created() {},
  methods: {
    go_to_homepage() {
      this.$router.push("/");
    },
    fetchImage(filenames) {
      return `${bucketUrlForImages}/${this.contentID}/${filenames}`;
    },
  },
});
</script>
