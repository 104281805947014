const serverUrl = process.env.VUE_APP_API_URL;

const bucketUrlForImages = "https://raisehero-storage-bucket.s3.eu-west-2.amazonaws.com/images/content";
const bucketUrlForImagesForRaffle = "https://raisehero-storage-bucket.s3.eu-west-2.amazonaws.com";

const apiURL = {
  fetchCustomRaffle: serverUrl + "/raffles/read",
  // raffleCheckout: serverUrl + "/checkout/add",
  raffleCheckout: serverUrl + "/user/payment/checkout",
  fetchUserTemplate: serverUrl + "/user/all/fetch",
  getPaymentFailure: serverUrl + "/user/payment/failure",
  getPaymentSuccess: serverUrl + "/user/payment/success",
  // getPaymentSuccess: "https://pay.dnapayments.com/checkout",
  // getPaymentFailure: "https://testmerchant/order/1123/fail",
  fetchSuccessPageData: serverUrl + "/user/payment/paymentDetails",
};

module.exports = { apiURL, bucketUrlForImages, bucketUrlForImagesForRaffle };
