<template>
  <div>
    <div class="row customRaffleNavBg m-0 position-relative">
      <div class="col-4 pe-0">
        <img
          src="https://static.raffolux.com/static/website/redev_images/poweredByRaffoluxImg.svg"
          class="customNavRaffoluxImg"
          alt=""
        />
      </div>
      <div class="col-4">
        <div class="d-flex justify-content-center">
          <img
            src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/GCR-logo.png"
            class="greatChristmasRaffleImg"
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      class="row customRafflesBody mob-customldngheroSecHide m-0"
      style="
        background-image: url(https://raffolux-static.s3.eu-west-2.amazonaws.com/static/website/media23/GCR/AYGO-LG3.webp);
      "
    >
      <div class="col-md-8">
        <p class="customSupportCharityTxt">{{ customraffleData1.Summary }}</p>
        <h1>Your chance to WIN a {{ customraffleData1.Title }}!</h1>
        <h5>Enter now for just £5 and support over 87 charities</h5>
      </div>
      <div class="col-md-4"></div>
    </div>
    <div class="web-customldngheroSecHide">
      <div class="position-relative">
        <img
          src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/rafAygoMobile_.webp"
          class="mb-customcarImg"
          alt=""
        />
        <p class="mb-customSupportTxt">{{ customraffleData1.Summary }}</p>
      </div>
      <h1 class="mb-customHdngTxt">
        Your chance to WIN a {{ customraffleData1.Title }}!
      </h1>
      <p class="mb-customHeroPara">
        Enter now for just £5 and support over 87 charities
      </p>
    </div>
    <!-- web carousel sec -->
    <charityCarosel />

    <div class="row customLandingPageAbtWngItemBg">
      <div class="col-6 p-0 mb-CustomAbtWinningStyles position-relative">
        <button @click="prevImage" class="">
          <em class="fa-solid fa-circle-chevron-left arrow-button"></em>
        </button>
        <img :src="selectedImage" class="customLdngPageWinningImg" alt="" />
        <button @click="nextImage" class="">
          <em class="fa-solid fa-circle-chevron-right arrow-button-right"></em>
        </button>
        <div class="mb-customSmallImgsPad">
          <img
            v-for="(thumbnail, index) in thumbnails"
            :key="index"
            :src="thumbnail.src"
            :alt="thumbnail.alt"
            class="customLdngwinningItemImgsList"
            @click="selectImage(index)"
            :class="{ active: selectedIndex === index }"
          />
        </div>
      </div>
      <div class="col-6 p-0 mb-CustomAbtWinningStyles mb-customRightSecPad">
        <div class="customLdngPageWinningDesc">
          <h6>
            <em class="fa-solid fa-gift customLandingGiftIcon"></em>THE PRIZE
          </h6>
          <h1>
            Win a {{ customraffleData1.Title }} – Your Ticket to Adventure!
          </h1>
          <p>
            Imagine cruising down the open road in your very own Toyota Aygo X
            Edge, a sleek and stylish ride that's ready to take you on
            unforgettable journeys. Whether it's your daily commute or a weekend
            getaway, this car will be your trusted companion on the road of
            life.
          </p>
          <p>
            And the best part? You’ll have your choice of over 87 charities to
            support - or choose to split your donation between all!
          </p>
          <p>A winner will be drawn on the 30th November 2023 22:00.</p>
        </div>
      </div>
    </div>
    <div class="customLandingBgColor">
      <div class="customLdngWinningItemDtls">
        <div class="row m-0 mb-customAbtSecBg">
          <div class="col-6 p-0 mb-CustomAbtWinningStyles">
            <div class="customLdngDtlsTxt">
              <h1>
                🚗 Toyota Aygo X Edge: Your Stylish and Reliable Companion
              </h1>
              <p>
                The Toyota Aygo X Edge boasts an efficient and peppy engine
                that's perfect for both city driving and longer journeys. Its
                compact size and nimble handling make it a breeze to maneuver
                through urban traffic and tight spaces.
              </p>
            </div>
          </div>
          <div class="col-6 p-0 mb-CustomAbtWinningStyles text-end">
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/Aygo1.webp"
              class="customLdngWinningItemImg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row customLdngTechAndConnectivitySecBg">
      <div class="col-7 p-0 mb-CustomAbtWinningStyles">
        <img
          src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/Aygo2.webp"
          class="customLdngWinningItemImg me-0"
          alt=""
        />
      </div>
      <div class="col-5 p-0 mb-CustomAbtWinningStyles">
        <div class="customLdngTechSecDtls">
          <h1>💡Technology and Connectivity</h1>
          <p>
            Stay connected and entertained with the Aygo X's modern technology
            features. Enjoy a user-friendly touchscreen infotainment system that
            offers smartphone integration, including Bluetooth connectivity,
            allowing you to make hands-free calls and stream your favorite music
            seamlessly.
          </p>
        </div>
      </div>
    </div>
    <div class="customLandingBgColor">
      <div class="row customLandingVideoSec">
        <div class="col-6 p-0 mb-CustomAbtWinningStyles">
          <iframe
            class="customVideoStyles"
            src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/Aygo_X.mp4"
          >
          </iframe>
        </div>
        <div class="col-6 p-0 mb-CustomAbtWinningStyles">
          <div class="customLdngVideoSecTxt">
            <p class="customLdngCharityTxt">
              <em class="fa-solid fa-ribbon customRaffleRibbonIcon"></em>FOR
              CHARITY
            </p>
            <h1 class="">🤝 Support Not One, but Multiple Worthy Causes!</h1>
            <h6>
              When you enter our raffle, you're not just entering for the car –
              you're contributing to the collective good. All proceeds from this
              raffle will go directly to a combination of deserving charities
              dedicated to causes such as education, healthcare, environment,
              and community development. Your participation will create a ripple
              of positive impact that reaches far and wide.
            </h6>
            <button
              type="button"
              class="btn customRaffleLearnBtn"
              @click="go_to_rafflepage"
            >
              Learn more
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- web footer sec -->
    <div class="webCustomFooter">
      <div class="row customRaffleFooterBg m-0">
        <div class="col-1"></div>
        <div class="col-3">
          <h2 class="customfooterSupportTxt">IN SUPPORT OF</h2>
          <div class="d-flex justify-content-center">
            <img
              src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/CE-VM.svg"
              alt=""
              class="footerCharityImg"
            />
          </div>
          <h3 class="customfooterSupportTxt customfooterFollowTxt">
            Follow us
          </h3>
          <div class="customFooterSocialMediaImgs">
            <a href="https://www.facebook.com/raffolux/" target="_blank">
              <img
                src="https://static.raffolux.com/static/website/redev_images/Facebook+-+Negative.svg"
                alt=""
            /></a>
            <a href="https://www.instagram.com/raffolux/"
              ><img
                src="https://static.raffolux.com/static/website/redev_images/Instagram+-+Negative.svg"
                alt=""
            /></a>
            <a href="https://twitter.com/raffolux/"
              ><img
                src="https://static.raffolux.com/static/website/redev_images/Twitter+-+Negative.svg"
                alt=""
            /></a>
            <a href="https://www.linkedin.com/company/raffolux/about/"
              ><img
                src="https://static.raffolux.com/static/website/redev_images/LinkedIn+-+Negative.svg"
                alt=""
            /></a>
          </div>
        </div>
        <div class="col-7">
          <div class="customFooterRightSec">
            <p class="customFooterRightSecTxt">
              This raffle is proudly brought to you by Raffolux.
            </p>
            <p class="customFooterRightSecTxt mb-0">
              {{ customraffleData.footer_text }}
            </p>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
      <div class="customFooterRaffoluxSec">
        <img
          src="https://static.raffolux.com/static/website/redev_images/poweredByRaffoluxImg.svg"
          class="customFooterRaffoluxImg"
          alt=""
        />
      </div>
    </div>
    <!-- web footer sec ends-->
    <!-- mob footer sec -->
    <div class="mobCustomFooter">
      <h2>IN SUPPORT OF</h2>
      <div class="d-flex justify-content-center">
        <img
          src="https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/CE-VM.svg"
          alt=""
          class="footerCharityImg"
        />
      </div>
      <div class="d-flex justify-content-center">
        <img
          src="https://static.raffolux.com/static/website/redev_images/poweredByRaffoluxImg.svg"
          class="mobCustomFooterRaffoluxImg"
          alt=""
        />
      </div>
      <h2>Follow us</h2>
      <div class="customFooterSocialMediaImgs">
        <a href="https://www.facebook.com/raffolux/" target="_blank"
          ><img
            src="https://static.raffolux.com/static/website/redev_images/Facebook+-+Negative.svg"
            alt=""
        /></a>
        <a href="https://www.instagram.com/raffolux/"
          ><img
            src="https://static.raffolux.com/static/website/redev_images/Instagram+-+Negative.svg"
            alt=""
        /></a>
        <a href="https://twitter.com/raffolux/"
          ><img
            src="https://static.raffolux.com/static/website/redev_images/Twitter+-+Negative.svg"
            alt=""
        /></a>
        <a href="https://www.linkedin.com/company/raffolux/about/"
          ><img
            src="https://static.raffolux.com/static/website/redev_images/LinkedIn+-+Negative.svg"
            alt=""
        /></a>
      </div>
      <p class="mobCustomFooterTxt mb-0 pb-0">
        This raffle is proudly brought to you by Raffolux.
      </p>
      <p class="mobCustomFooterTxt mt-0 pb-0">
        {{ customraffleData.footer_text }}
      </p>
      <h5 class="customFooterWinTxt">WIN A {{ customraffleData1.Title }}</h5>

    </div>

  </div>
</template>

<script>
import $ from "jquery";
import "@/assets/css/customRaffleLandingPage.css";
import "@/assets/css/global.css";
import "@/assets/css/toggle.css";
import "slick-carousel/slick/slick.min.js";
import "slick-carousel/slick/slick.css";
import "@/assets/slick/slick.css";
import "@/assets/slick/slick-theme.css";
import moment from "moment";
import charityList from "@/mixins/charity.json";
// Components
import charityCarosel from "../../../components/common/gcr/carousel.vue";
import { apiURL } from "@/api/api";

export default {
  data: () => ({
    countdownTimers: [
      {
        days: "00",
        hours: "00",
        minutes: "00",
        secs: "00",
      },
    ],
    customraffleData: [],
    customraffleData1: [],
    raffleCode: "",
    charityRef: "",
    charityList,
    selectedIndex: 0,
    thumbnails: [
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb2.webp",
        alt: "Thumbnail 1",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb3.webp",
        alt: "Thumbnail 2",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb9.webp",
        alt: "Thumbnail 3",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb4.webp",
        alt: "Thumbnail 4",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb5.webp",
        alt: "Thumbnail 5",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb6.webp",
        alt: "Thumbnail 6",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb7.webp",
        alt: "Thumbnail 7",
      },
      {
        src: "https://static.raffolux.com/static/website/whitelabel/greatxmasraffle/GCR23/thumbnails/rafAygo_tb8.webp",
        alt: "Thumbnail 8",
      },
    ],
  }),
  mounted() {
    this.$nextTick(() => {
      this.slick();
    });
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    this.charityRef = !urlParams.get("charity_ref")
      ? 0
      : urlParams.get("charity_ref");
    this.raffleCode = 4015;
    this.fetch_custom_raffle();
  },
  components: {
    charityCarosel,
  },
  computed: {
    selectedImage() {
      return this.thumbnails[this.selectedIndex].src;
    },
  },
  methods: {
    slick() {
      $(".autoplay").slick({
        slidesToShow: 18,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              autoplay: true,
              autoplaySpeed: 0,
            },
          },
        ],
      });
    },
    selectImage(index) {
      this.selectedIndex = index;
    },
    prevImage() {
      this.selectedIndex =
        (this.selectedIndex - 1 + this.thumbnails.length) %
        this.thumbnails.length;
    },
    nextImage() {
      this.selectedIndex = (this.selectedIndex + 1) % this.thumbnails.length;
    },
    startCountdown() {
      setInterval(() => {
        const inputDateStr = "30th November 2023 22:00";
        const parsedDate = new Date(
          inputDateStr.replace(/(\d+)(st|nd|rd|th)/, "$1")
        );
        const formattedDate = parsedDate.toISOString();
        const now = moment();
        const endOfYear = moment(formattedDate);
        const duration = moment.duration(endOfYear.diff(now));
        const totalDays = duration.asDays();
        const days = String(parseInt(totalDays)).padStart(2, "0");
        const hours = String(duration.hours()).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");
        const seconds = String(duration.seconds()).padStart(2, "0");
        this.countdownTimers = [
          {
            days: days,
            hours: hours,
            minutes: minutes,
            secs: seconds,
          },
        ];
      }, 1000);
    },
    async fetch_custom_raffle() {
      try {
        await fetch(
          `${apiURL.fetchCustomRaffle}/?raffleCode=${this.raffleCode}`,
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            this.customraffleDetails = data;
            // console.log("this.customraffleDetails", this.customraffleDetails)
            this.customraffleData =
              this.customraffleDetails.data.customRaffle[0];
            this.customraffleData1 = this.customraffleDetails.data.raffle[0];
            this.startCountdown();
          });
      } catch (error) {
        console.log("error:", error);
      }
    },

    formatDate(dateString) {
      return moment(dateString).format("Do MMMM YYYY [at] HH:mm");
    },
    go_to_rafflepage() {
      const queryParams = {
        charityRef: this.charityRef,
        raffleCode: this.raffleCode,
      };
      this.$router
        .push({
          name: "RafflePage",
          params: queryParams,
        })
        .catch((err) => {
          console.error(err);
        });
      // this.$router.push(`/customRafflePage/${this.charityRef}/${this.raffleCode}`)
    },
  },
};
</script>
