<template>
  <div v-if="isLoading">
    <loaderVue />
  </div>
  <div>
    <!-- <rafflelandingPage /> -->
     <!-- Dynamic Header -->
    <component
      :is="headerComponent"
      :poweredBy="poweredBy"
      :headerLogoSrc="headerLogoSrc"
      :headerData="headerData"
      :themeData="themeData"
      :contentID="contentID"
      :templateSample="pageTemplate"
      :isPowerLogoRequired="true"
      v-if="this.headerData.is"
    />
    <!-- <Header
      :poweredBy="poweredBy"
      :headerLogoSrc="headerLogoSrc"
      :headerData="headerData"
      :themeData="themeData"
      :contentID="contentID"
      :templateSample="pageTemplate"
      :isPowerLogoRequired="true"
    /> -->
    <customRafflePage
      :themeData="themeData"
      :pageContent="pageContent"
      :templateRaffle="templateRaffle"
      :contentID="contentID"
      :raffleDetails="raffleDetails"
      :soldTicketCount="soldTicketCount"
      :raffleContentTemplateDetails="raffleContentTemplateDetails"
      :winner="winner"
    />
    <!-- <Footer
      :isRaffle="true"
      :footerTitle="footerTitle"
      :footerText="footerText"
      :assetSource="assetSource"
      :footerData="footerData"
      :themeData="themeData"
      :contentID="contentID"
      :footerContent="footerContent"
    /> -->
    <!-- Dynamic Footer -->
    <component
      :is="footerComponent"
      :isRaffle="false"
      :isRafflePage="true"
      :footerTitle="footerTitle"
      :footerText="footerText"
      :assetSource="assetSource"
      :footerData="footerData"
      :countDownTime="countDownTime"
      :themeData="themeData"
      :contentID="contentID"
      :footerContent="footerContent"
      :winner="winner"
    />
  </div>
</template>

<script>
import { ref, watch, onMounted,markRaw } from 'vue';
import raffleHeaderVue from "@/components/common/gcr/raffle/raffleHeader.vue";
import Header from "@/components/common/gcr/header.vue";
import Footer from "@/components/common/gcr/footer.vue";

import "../../../assets/css/RafflePage/raffle.css";

import contentsVue from "@/components/common/gcr/raffle/contents.vue";
import rafflelandingPage from "../reference/rafflelandingPage.vue";
import customRafflePage from "@/components/common/gcr/raffle/customRafflePage.vue";
import loaderVue from "@/components/common/gcr/shared/loader.vue";

import { apiURL } from "@/api/api";

export default markRaw({
  data: () => ({
    folderToSelect:"",

    //Header
    poweredBy: "",
    headerLogoSrc: "",
    headerData: {},
    isLoading: false,

    pageTemplate: {},
    pageContent: {},
    raffleDetails: {},
    templateRaffle:{},
    contentID: "",
    themeData: {},
    footerTitle: "",
    footerText: "",
    assetSource: "",
    footerData: {},
    footerContent: {},
    soldTicketCount: 0,
    raffleContentTemplateDetails:{},

    winner:{}
  }),
  components: {
    Header,
    Footer,
    raffleHeaderVue,
    contentsVue,
    rafflelandingPage,
    customRafflePage,
    loaderVue,
  }, 

  created() {
      this.smoothScrollToTop();
      this.fetchRaffleTemplate();
  },

  setup() {
    const selectFolder = ref(''); // or any dynamic value you have
    const isLoading = ref(false);
    const headerComponent = ref(null);
    const footerComponent = ref(null);
    
    // Function to dynamically load components
    const loadComponents = async () => {
      const folderName = selectFolder?.value?.split('#')[1];

      try {
        // Dynamically import the header and footer components
        headerComponent.value = (await import(`@/components/common/client/${folderName}/Header.vue`)).default;
        footerComponent.value = (await import(`@/components/common/client/${folderName}/Footer.vue`)).default;
        
      } catch (error) {
        console.error(`Error loading components for folder ${folderName}:`, error);
        // Handle errors (e.g., show an error message or default components)
        headerComponent.value = Header; // You might set a fallback component or handle this case
        footerComponent.value = Footer; // Same as above
      }
    };

    // Load components on component mount or when selectFolder changes
    onMounted(() => {
      loadComponents();
    });

    watch(selectFolder, () => {
      loadComponents();
    });

    return {
      selectFolder,
      isLoading,
      headerComponent,
      footerComponent,
      // Add other data and methods here as needed
    };
  },
  methods: {    
    smoothScrollToTop() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > 0) {
        window.requestAnimationFrame(this.smoothScrollToTop);
        window.scrollTo(0, scrollTop - scrollTop / 8);
      }
   },
    async fetchRaffleTemplate() {
      this.isLoading = true;
      try {
        await fetch(apiURL.fetchUserTemplate, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            this.pageTemplate = data?.Items.template;
            this.raffleContentTemplateDetails =
              this.pageTemplate?.main?.page_contents?.pages[1].contents[0];
            this.pageContent = data?.Items.content;
            this.raffleDetails = data?.Items.raffle;
            this.pageFooterContent = data?.Items.content?.footer;
            this.contentID = data?.Items.content?.sort_id?.split("#")[1];
            this.themeData = data?.Items.template?.theme?.light_css;
            
            this.templateRaffle = data?.Items.template?.main.page_contents.pages[1];
            this.headerData = {
              is: this.pageTemplate?.header?.is,
              name: this.pageTemplate?.header?.name?.is
                ? this.pageContent.header.name.value
                : "",
              nameImgSrc: this.pageTemplate?.header?.name?.is_image
                ? this.pageContent.header.name.value
                : "",
              nameLink: this.pageTemplate?.header?.name?.is_link
                ? this.pageContent.header.name.link.value
                : "",

              logo: this.pageTemplate?.header?.logo?.is
                ? this.pageContent.header.logo.is
                : "",
              logoImgSrc: this.pageTemplate?.header?.logo?.is_image
                ? this.pageContent.header.logo.value
                : "",
              logoLink: this.pageTemplate?.header?.logo?.is_link
                ? this.pageContent.header.logo.link.value
                : "",

              poweredByLogo: this.pageTemplate?.header?.powered_by_logo?.is
                ? this.pageContent.header.powered_by_logo.is
                : "",
              poweredByLogoImgSrc: this.pageTemplate?.header?.powered_by_logo
                ?.is_image
                ? this.pageContent.header.powered_by_logo.value
                : "",
              poweredByLogoLink: this.pageTemplate?.header?.powered_by_logo
                ?.is_link
                ? this.pageContent.header.powered_by_logo.link.value
                : "",
            };
            this.folderToSelect=this.pageContent.sort_id
            this.footerData = this.pageTemplate.footer;
            this.footerContent = this.pageContent.footer;

            this.soldTicketCount = data?.Items?.ticketCount;

            // Dynamically rendering header and  footer
            this.selectFolder = this.folderToSelect;


            this.winner=data?.Items?.winner ? data?.Items?.winner : {}
          });
      } catch (error) {
        console.log("error:", error);
      }
      this.isLoading = false;
    },
  },
});
</script>
